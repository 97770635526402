import React, {useContext, useEffect, useState} from "react"
import {navigate} from "gatsby"
import {AppSync} from "../../libraries/appsync"
import qs from "qs"
import _ from "lodash"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import ProductDetail from "../../components/ProductDetail"
import {titleString} from "../../components/ProductTitle"
import PageNavigation from "../../components/PageNavigation"
import Button, {ButtonClose} from "../../components/Button"
import CartInfo from "../../components/CartInfo"
import CartContext from "../../components/Context/CartContext"
import classNames from "classnames"
import {Preview, cryptString} from "../../libraries/preview"

export default ({location}) => {
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState([])
  const [productGroup, setProductGroup] = useState(null)
  const [cartProducts, setCartProducts] = useState([])
  const [cartModal, setCartModal] = useState(false)
  const {cartInfo} = useContext(CartContext)
  const productId = _.get(qs.parse(location.search, {ignoreQueryPrefix: true}), "id")

  useEffect(() => {
    setLoading(true)

    Preview(cryptString(location)).then(customDateTime => {
      const productQuery = Hin => customDateTime ? `(Hin:"${Hin}", preview:"${customDateTime}")` : `(Hin:"${Hin}")`

      Promise.all([
        AppSync.post("/", {
          query: `
          query getProduct {
            getProduct${productQuery(productId)} {
              Hin
              Title
              Release
              OrderDateFrom
              OrderDateTo
              Price
              NetPrice
              Status
              Limit
              Stock
              PreType
              PreFromDate
              PreToDate
              PreType
              ecCategory {
                member
              }
              ecImage
              ecItemName
              ecItemOrder
              ecBody
              ecReleaseDate
              ecSellingStatus
              ecSort
              ecFanclub {
                name
                key
                site
                user
                url
              }
            }
          }
        `
        }),
        AppSync.post("/", {
          query: `
          query getGroup {
            getGroup(hinban:"${productId}") {
              hinban
              parent
              child
              groupName
            }
          }
        `
        }),
      ]).then(resAll => {
        const parentProductData = _.get(resAll, "0.data.data.getProduct")
        const group = _.get(resAll, "1.data.data.getGroup")

        // 子商品に直接飛んできた場合は親商品にリダイレクトさせる
        if (group && group.parent !== null) {
          navigate(`/product/detail/?id=${group.parent}`)
          return
        }

        setProduct(parentProductData)
        setProductGroup(group)

        if (group && group.child !== null) {
          const children = group.child

          Promise.all(_.map(children, c =>
            AppSync.post("/", {
              query: `
                query getProduct {
                  getProduct${productQuery(c)} {
                    Hin
                    Title
                    Release
                    OrderDateFrom
                    OrderDateTo
                    Price
                    NetPrice
                    Status
                    Limit
                    Stock
                    PreType
                    PreFromDate
                    PreToDate
                    ecCategory {
                      member
                    }
                    ecImage
                    ecItemName
                    ecItemOrder
                    ecBody
                    ecReleaseDate
                    ecSellingStatus
                    ecFanclub {
                      name
                      key
                      site
                      user
                    }
                  }
                }
              `
            })
          )).then(all => {
            // 親商品追加
            const data = [parentProductData]
            all.forEach(a => data.push(_.get(a, "data.data.getProduct")))
            setCartProducts(_.orderBy(data, ["ecItemOrder"]))
            setLoading(false)
          })
        } else {
          setCartProducts([parentProductData])
          setLoading(false)
        }
      })
    })
  }, [productId])

  return (
    <Layout>
      <SEO title={titleString(product, productGroup)} description={_.get(product, "ecBody")}/>
      <section className="constraint">
        <ProductDetail loading={loading} item={product} productGroup={productGroup} cartProducts={cartProducts} location={location} successCallback={() => setCartModal(true)}/>
        <PageNavigation>
          <Button type={"button"} attr={{
            onClick: () => navigate("/product/")
          }}>一覧へ戻る</Button>
        </PageNavigation>
      </section>
      <div className={classNames("modal", {"modal-active": cartModal})}>
        <div style={{
          position: "relative",
          margin: "30px",
          textAlign: "right",
          zIndex: 2,
        }}>
          <ButtonClose onClick={() => setCartModal(false)}/>
        </div>
        <div style={{
          width: "100%",
          height: "calc(100% - 100px)",
          overflow: "auto",
          padding: "30px 0",
        }}>
          <div className="container">
            <CartInfo cartInfo={cartInfo} loading={loading} onClickBackButton={() => setCartModal(false)}/>
          </div>
        </div>
      </div>
    </Layout>
  )
}